import setMask from '@/helpers/string/setMask';

export default {
    computed: {
        phone() {
            return setMask(this.$DICTIONARY.phone, '# (###) ###-##-##')
        },
        phoneLink() {
            return this.$DICTIONARY.phone
        },
        email() {
            return process.env.VUE_APP_EMAIL
        }
    }
}