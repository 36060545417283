import store from '@/store'

export default async (to, from, next) => {
    if (!store.state.application.init) {
        await store.dispatch('dictionary/init')

        const promises = Promise.all([store.dispatch('application/update')])

        if (window.location.pathname.includes('/primary')) {
            const params = new URL(document.location).searchParams
            if (params.get('cpa') === '1') {
                next()
                return;
            }
        }

        await promises
    }

    next()
}