import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import application from './modules/application'
import dictionary from './modules/dictionary'
import error from './modules/error'
import personal from './modules/personal'

export default new Vuex.Store({
    modules: {
        application,
        dictionary,
        error,
        personal
    },
    strict: true,
})
