<template>
    <button
        class="i-button"
        :type="type"
    >
        <span class="i-button__content">
            <slot/>
        </span>
    </button>
</template>

<script>
import './i-button.scss';

export default {
    name: 'Button',
    props: {
        type: {
            type: String,
            default: 'button'
        }
    },
};
</script>
