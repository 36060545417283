<template>
    <div style="width: 100%">
        <transition name="sidebar" appear
        >
            <MobileMenu
                v-if="menu"
                @close="menu=false"
            />
        </transition>
        <header class="header">
            <div class="header__content">
                <div>
                    <a
                        v-if="$route.name === 'Landing'"
                        href="/"
                        class="logo"
                    ></a>
                    <router-link
                        v-else
                        :to="{ name: 'Landing' }"
                        class="logo sub"
                    ></router-link>
                </div>
                <div class="phone" v-if="!isMenu && $route.name === 'Landing'">
                    <a class="link-hover" :href="`tel:${phoneLink}`">{{ phone }}</a>
                </div>
                <base-button class="link" link to="/primary" mode="black" v-if="!isMenu && $route.name === 'Landing'">Получить займ</base-button>
                <div class="header__mobile">
                    <span class="header__burger" @click="menu=true"><span></span></span>
                </div>
            </div>
        </header>
    </div>
</template>

<script>
import './header.scss';
import contacts from '@/mixins/contacts';
import mobile from '@/mixins/mobile';

export default {
    name: 'Header',
    mixins: [
        contacts,
        mobile
    ],
    data() {
        return {
            menu: false
        };
    },
    methods: {
        openMenu() {
            this.menu = true;
        },
    },
    components: {
        MobileMenu: () => import('./MobileMenu'),
    }
};
</script>
