export default [
    {
        path: '/login',
        component: () => import('@/applications/login/App'),
        children: [
            {
                path: '',
                name: 'Login',
                component: () => import('@/applications/login/views/login/Login')
            },
            {
                path: 'code',
                name: 'LoginCode',
                component: () => import('@/applications/login/views/code/Code')
            }
        ]
    }
]