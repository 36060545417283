export default [
    {
        path: '/lk',
        component: () => import('@/applications/personal-area/App'),
        redirect: '/lk/profile',
        children: [
            {
                path: 'suggestions',
                name: 'PersonalAreaLoans',
                component: () => import('@/applications/personal-area/views/loans/Loans')
            },
            {
                path: 'profile',
                name: 'PersonalAreaProfile',
                component: () => import('@/applications/personal-area/views/profile/Profile')
            },
            {
                path: 'edit',
                name: 'PersonalAreaEdit',
                component: () => import('@/applications/personal-area/views/edit/Edit')
            },
            {
                path: 'save-changes',
                name: 'SaveChanges',
                component: () => import('@/applications/personal-area/views/save-changes/SaveChanges')
            },
            {
                path: 'documents',
                name: 'PersonalAreaDocuments',
                component: () => import('@/applications/personal-area/views/documents/Documents')
            },
            {
                path: 'feedback',
                name: 'PersonalAreaFeedback',
                component: () => import('@/applications/personal-area/views/feedback/Feedback')
            },
            {
                path: 'subscription',
                name: 'PersonalAreaSubscription',
                component: () => import('@/applications/personal-area/views/subscription/Subscription')
            },
            // {
            //     path: 'services',
            //     name: 'PersonalAreaServices',
            //     component: () => import('@/applications/personal-area/views/services/Services')
            // },
            // {
            //     path: 'reports',
            //     name: 'PersonalAreaReports',
            //     component: () => import('@/applications/personal-area/views/reports/Reports')
            // },
        ]
    }
]