import kabinet from '@/api/kabinet'

export default {
    state: {
        info: {}
    },
    getters: {
        info({ info }) {
            return info
        },
        data({ info }) {
            return info.data
        },
        propose({ info }) {
            return info.propose
        }
    },
    mutations: {
        updateInfo(state, data) {
            state.info = data
        }
    },
    actions: {
        async getInfo({ commit }) {
            const response = await kabinet()

            commit('updateInfo', response)
        }
    },
    namespaced: true
}