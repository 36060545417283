export default [
    {
        path: '/',
        component: () => import('@/applications/loan-application/App'),
        children: [
            {
                path: '/anticharge',
                name: 'Anticharge',
                component: () => import('@/applications/loan-application/views/primary/Primary')
            },
            {
                path: '/primary',
                name: 'LoanPrimary',
                component: () => import('@/applications/loan-application/views/primary/Primary')
            },
            {
                path: '/auth',
                name: 'LoanAuth',
                component: () => import('@/applications/loan-application/views/auth/Auth')
            },
            {
                path: '/contact',
                name: 'LoanContact',
                component: () => import('@/applications/loan-application/views/contact/Contact')
            },
            {
                path: '/before',
                name: 'LoanBefore',
                component: () => import('@/applications/loan-application/views/before/Before')
            },
            {
                path: '/card',
                name: 'LoanCard',
                component: () => import('@/applications/loan-application/views/card/Card')
            },
        ]
    },
    {
        path: '/secure',
        name: 'LoanCardSecure',
        component: () => import('@/applications/loan-application/views/card-secure/3ds/ThreeDS')
    }
]