export default [
    {
        path: '',
        component: () => import('@/applications/unsubscribe/App'),
        children: [
            {
                path: '/unsubscribe',
                component: () => import('@/components/common/empty/Empty'),
                children: [
                    {
                        path: '',
                        name: 'Unsubscribe',
                        component: () => import('@/applications/unsubscribe/views/unsubscribe/Unsubscribe')
                    },
                    {
                        path: 'success',
                        name: 'UnsubscribeMessage',
                        component: () => import('@/applications/unsubscribe/views/unsubscribe-message/UnsubscribeMessage')
                    },
                    {
                        path: 'info',
                        name: 'UnsubscribeInfo',
                        component: () => import('@/applications/unsubscribe/views/unsubscribe-info/UnsubscribeInfo')
                    },
                    {
                        path: 'code',
                        name: 'UnsubscribeSms',
                        component: () => import('@/applications/unsubscribe/views/unsubscribe-sms/UnsubscribeSms')
                    },
                ]
            },
            {
                path: 'ticket/:uid',
                name: 'TicketInfo',
                component: () => import('@/applications/unsubscribe/views/ticket-info/TicketInfo')
            }
        ]
    },
]